import {Component, OnDestroy, OnInit} from '@angular/core';
import {DashboardWidgets, Organization, PropertyManagerDashboardWidgets, Theme, User} from '@ee/common/models';
import {NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {combineLatest, Subscription} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromApp from '../core/reducers/app.reducer';
import {LayoutService} from '../core/services/layout.service';
import {LoadWorkflows} from '../core/actions/workflow.actions';
import {environment} from '../../environments/environment';
import {AccountType, Permission, SubscriptionPlan, SubscriptionStatus} from '@ee/common/enums';
import {UserAgreementDialogComponent} from './components/user-agreement-dialog.component';
import {UserDetailsUpdated} from '../core/actions/auth.actions';
import {MatDialog} from '@angular/material/dialog';
import {productFruits} from 'product-fruits';
import {ShowReferralDialog} from '../core/actions/dialog.actions';

@Component({
  selector: 'ee-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss'],
  standalone: false
})
export class PortalComponent implements OnInit, OnDestroy {
  currentTheme: Theme = {
    id: 'eeviction-default',
    name: 'eEviction Default',
    url: 'theme-default.css',
    sidebar: '#424242',
    toolbar: '#FFF',
    content: '#FFF',
    primary: '#455a64',
    accent: '#d50000'
  };

  user: User;
  org: Organization;
  dashboardWidgets: DashboardWidgets | PropertyManagerDashboardWidgets;
  currentPlan: SubscriptionPlan;
  plans = SubscriptionPlan;
  isEvictionRoute: boolean;
  title: string;
  themeId: string;
  subs: Subscription[] = [];
  isAdmin = false;
  showUserRolesFeature = environment.user_roles_feature;
  subscriptionStatus = SubscriptionStatus;
  initDataRequested = false;
  orgTypes = AccountType;
  permissions = Permission;

  constructor(
    private layoutService: LayoutService,
    private store: Store,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.title = 'EasyEviction Portal';

    // subscribe to current route
    this.subs.push(
      this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
        if (event instanceof NavigationStart) {
          this.isEvictionRoute = event.url?.startsWith('/evictions');
        }
      })
    );
  }

  ngOnInit() {
    this.layoutService.setCurrentTheme(this.currentTheme);

    this.subs.push(
      combineLatest([
        this.store.select(fromApp.getLoggedInUser),
        this.store.select(fromApp.getLoggedInOrg),
        this.store.select(fromApp.getDashboardWidgets),
        this.store.select(fromApp.getCurrentPlan)
      ]).subscribe(([user, organization, dashboardWidgets, currentPlan]) => {
        this.user = user;
        this.org = organization;
        this.isAdmin = user?.is_admin;
        this.currentPlan = currentPlan;
        if (user && organization) {
          this.dashboardWidgets = dashboardWidgets;

          if (!this.initDataRequested) {

            // show user agreement dialog if both have not been confirmed
            if (!user.tos_confirm || !user.policy_confirm) {
              const dialogRef = this.dialog.open(UserAgreementDialogComponent,
                {width: '500px', closeOnNavigation: false, disableClose: true});
              this.subs.push(dialogRef.afterClosed().subscribe((updatedUser: User) => {
                if (updatedUser) {
                  this.store.dispatch(UserDetailsUpdated(updatedUser));
                }
              }));
            }

            if (this.org.type === this.orgTypes.ATTORNEY) {
              this.store.dispatch(new LoadWorkflows());
            }

            this.initDataRequested = true;
          }
        }
      })
    );
  }

  openSupport() {
    productFruits.safeExec(() => {
      if ((window as any).productFruits.api.inAppCenter.isOpened()) {
        (window as any).productFruits.api.inAppCenter.close();
      } else {
        (window as any).productFruits.api.inAppCenter.open();
      }
    });
  }

  get unreadMessageCount(): number | undefined {
    return (this.dashboardWidgets as DashboardWidgets)?.unread_message_count;
  }

  get intakeEvictionCount(): number | undefined {
    return (this.dashboardWidgets as DashboardWidgets)?.intake_eviction ?? 0;
  }

  get taskCount(): number | undefined {
    return (this.dashboardWidgets as DashboardWidgets)?.task_count ?? 0;
  }

  openReferralDialog() {
    this.store.dispatch(ShowReferralDialog());
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  protected readonly accountType = AccountType;
  protected readonly permission = Permission;
}
