import { Component, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BillableItem, User } from '@ee/common/models';
import { Store } from '@ngrx/store';
import { UserService } from '@ee/common/services';
import { Subscription } from 'rxjs';
import { ShowAutoClosableAlert } from '../../core/actions/alert.actions';

@Component({
  selector: 'ee-user-agreement-dialog',
  template: `
    <h2 mat-dialog-title>Review Terms</h2>

    <form mat-dialog-content class="flex flex-col" [formGroup]="form">
      <p>Please review and agree to our Terms of Service and Privacy Policy.</p>
      <div>
        <mat-checkbox formControlName="tos_confirm">
          <div class="terms-condition">I agree to the EasyEviction
            <a href="https://easyeviction.com/terms/" target="_blank">Terms of Services</a>
          </div>
        </mat-checkbox>
        <mat-checkbox formControlName="policy_confirm">
          <div class="terms-condition">I agree to the EasyEviction
            <a href="https://easyeviction.com/privacy/" target="_blank">Privacy Policy</a>
          </div>
        </mat-checkbox>
      </div>
    </form>

    <div mat-dialog-actions class="ee-modal-actions">
      <button mat-button (click)="confirm()" cdkFocusInitial color="accent" [disabled]="form.invalid">
        CONTINUE
      </button>
    </div>
  `,
  styles: [`
  `],
  standalone: false
})
export class UserAgreementDialogComponent implements OnDestroy {
  form: UntypedFormGroup;

  subs: Subscription[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    public dialogRef: MatDialogRef<UserAgreementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public billableItem: BillableItem,
    private store: Store
  ) {
    this.form = this.formBuilder.group({
      tos_confirm: [null, Validators.required],
      policy_confirm: [null, Validators.required]
    });
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  confirm() {
    this.subs.push(
      this.userService.confirmAgreements().subscribe({
        next: (updatedUser: User) => {
          if (updatedUser) {
            this.dialogRef.close(updatedUser);
          }
        },
        error: () => {
          this.store.dispatch(new ShowAutoClosableAlert('Error confirming TOS and Privacy Policy. Please try again.'));
        }
      })
    );
  }
}
