import {Component, OnDestroy, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import * as fromApp from '../../../core/reducers/app.reducer';
import {Store} from '@ngrx/store';
import {Logout} from '../../../core/actions/auth.actions';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {DashboardWidgets, Organization, PropertyManagerDashboardWidgets, User} from '@ee/common/models';
import {AccountType, Permission, SubscriptionPlan} from '@ee/common/enums';
import {ShowTour} from '../../../core/actions/guided-tour.actions';
import {ShowOrgSwitcherDialog} from '../../../core/actions/dialog.actions';
import {MatMenuTrigger} from '@angular/material/menu';
import {environment} from '../../../../environments/environment';
import {ToolsService} from '@ee/common/services';
import {HttpResponse} from '@angular/common/http';
// import {productFruits} from 'product-fruits';

@Component({
  selector: 'ee-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  standalone: false
})
export class UserMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  subs: Subscription[] = [];
  currentUser: User;
  currentOrg$: Observable<Organization>;
  currentPlan$: Observable<SubscriptionPlan>;
  dashboardWidgets: DashboardWidgets | PropertyManagerDashboardWidgets;
  plans = SubscriptionPlan;
  orgTypes = AccountType;
  currentVersion = environment.version;
  permissions = Permission;
  @ViewChild('userDetailsMenuTrigger') userDetailsMenuTrigger: MatMenuTrigger;
  // @ViewChild('whatsnew') whatsnew: MatMenuItem;

  constructor(private store: Store, private toolsService: ToolsService) {
  }

  ngOnInit(): void {
    this.currentOrg$ = this.store.select(fromApp.getLoggedInOrg);
    this.currentPlan$ = this.store.select(fromApp.getCurrentPlan);

    this.subs.push(
      combineLatest([
        this.store.select(fromApp.getLoggedInUser),
        this.store.select(fromApp.getDashboardWidgets)
      ]).subscribe(([loggedInUser, dashboardWidgets]) => {
        this.currentUser = loggedInUser;
        this.dashboardWidgets = dashboardWidgets;
      })
    );
  }

  ngAfterViewInit() {
    // if (this.currentUser?.organization?.type === AccountType.ATTORNEY && this.whatsnew) {
    //   productFruits.safeExec(() => {
    //     (window as any).productFruits.api.announcementsV2.attachNewsWidgetToElement(this.whatsnew?._getHostElement());
    //   });
    // }
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  startTour(currentOrg: Organization): void {
    if (currentOrg.type === AccountType.ATTORNEY) {
      this.store.dispatch(new ShowTour(environment.product_tours.attorney_start, true));
    } else if (currentOrg.type === AccountType.CLIENT) {
      this.store.dispatch(new ShowTour(environment.product_tours.client_start, true));
    }
  }

  openRoadmap() {
    this.subs.push(this.toolsService.getRoadmapUrl().subscribe({
      next:(response: HttpResponse<string>) => {
        // open url in new tab
        (window as any).open(response.body, '_blank');
      }
    }));
  }

  generatePlanDisplay(type: AccountType, plan: SubscriptionPlan): string {
    if (type === AccountType.ATTORNEY) {
      return plan ? plan + ' PLAN' : 'No Plan Selected';
    } else if (type === AccountType.CLIENT) {
      return plan ? plan.replace('CLIENT_', '') + ' PLAN' : 'FREE PLAN';
    }

    return '';
  }

  switchOrganization(currentOrgId: string) {
    this.store.dispatch(ShowOrgSwitcherDialog(currentOrgId));
  }

  signOut() {
    this.store.dispatch(Logout());
  }
}
