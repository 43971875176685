import {MatSidenav} from '@angular/material/sidenav';
import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Action, Store} from '@ngrx/store';
import * as fromApp from '../../../core/reducers/app.reducer';
import * as fromNotification from '../../../core/reducers/notification.reducer';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {Account, Organization, SearchSuggestionModel, TitleOptions, User} from '@ee/common/models';
import {AccountType, Permission, SearchResultType, SubscriptionStatus} from '@ee/common/enums';
import {RECENT_SEARCHES} from '@ee/common/constants';
import {Router} from '@angular/router';
import {ShowAddClientDialog, ShowInvoiceCreationDialog, ShowTaskDialog} from '../../../core/actions/dialog.actions';
import {SearchService, UserService} from '@ee/common/services';
import {FormControl, UntypedFormControl} from '@angular/forms';
import {debounceTime, finalize, switchMap, tap} from 'rxjs/operators';
import {LocalStorageService} from 'ngx-webstorage';
import * as notificationActions from '../../../core/actions/notification.actions';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import * as fromRoot from '../../../core/reducers/app.reducer';
import {GetLoggedInUser} from '../../../core/actions/auth.actions';
import sortBy from 'lodash-es/sortBy';
import {productFruits} from 'product-fruits';

@Component({
  selector: 'ee-top-horizontal-menu',
  templateUrl: './top-horizontal-menu.component.html',
  styleUrls: ['./top-horizontal-menu.component.scss'],
  standalone: false
})
export class TopHorizontalMenuComponent implements OnInit, OnDestroy {

  constructor(private store: Store,
              private searchService: SearchService,
              private router: Router,
              private $localStorage: LocalStorageService,
              private responsive: BreakpointObserver,
              private userService: UserService) {
  }

  /**
   * Import left sidenav so we can access open close functions.
   */
  @Input() leftSidenav: MatSidenav;

  /**
   * Import right sidenav so we can access open close functions.
   */
  @Input() rightSidenav: MatSidenav;

  /**
   * Makes the toolbar transparent.
   */
  @Input() transparent = false;

  /**
   * Section title.
   */
  @Input() title: string;

  @ViewChild('search') private searchBox: ElementRef;


  public smallBkPt$: Observable<BreakpointState>;
  public pageTitle: string;
  public subTitle: string;
  public pageTitleButton: TitleOptions;
  public isLoading = false;
  private subs: Subscription[] = [];
  public lastSearch: string;
  searchControl: FormControl<string> = new UntypedFormControl();

  searchSuggestions$: Observable<SearchSuggestionModel[]> = of([]);
  notificationsChecked = false;
  client$: Observable<Organization>;
  user$: Observable<User>;
  accountTypes = AccountType;
  saving$: Observable<boolean>;
  notificationCount$: Observable<number>;
  searchResultType = SearchResultType;
  subscriptionStatus = SubscriptionStatus;
  recentSearches: string[] = [];
  activeAccounts: Account[] = [];
  totalInvitedAccounts = 0;
  permissions = Permission;
  canCreateClient = false;
  canCreateTask = false;
  canCreateCase = false;
  canCreateInvoice = false;


  ngOnInit() {
    this.smallBkPt$ = this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small]);
    this.client$ = this.store.select(fromApp.getLoggedInOrg);
    this.user$ = this.store.select(fromApp.getLoggedInUser);
    this.saving$ = this.store.select(fromApp.getIsSaving);
    this.notificationCount$ = this.store.select(fromNotification.getTotalUnreadNotificationCount);

    this.subs.push(combineLatest([
      this.store.select(fromApp.selectRouteData),
      this.userService.accounts(),
      this.store.select(fromRoot.getLoggedInOrg),
      this.store.select(fromRoot.getLoggedInUser),
    ]).subscribe(([routeData, accounts, currentOrg, currentUser]) => {
      if (routeData) {
        this.pageTitle = routeData.pageTitle ? routeData.pageTitle : '';
        this.subTitle = routeData.subTitle ? routeData.subTitle : '';
        this.pageTitleButton = routeData.pageTitleButton;
      }
      if (accounts && accounts.length && currentOrg) {
        this.activeAccounts = sortBy(accounts.filter(x => x.link_status === 'Linked' && currentOrg?.id != x.id), ['company_name']);
        this.totalInvitedAccounts = accounts.filter(x => x.link_status === 'Pending' || x.link_status === 'Pending New Client').length;
      }
      if (currentUser && currentOrg) {
        this.canCreateClient = currentUser.permissions.includes(Permission.CLIENT_GENERAL_CREATE) && currentOrg.type === AccountType.ATTORNEY;
        this.canCreateCase = currentUser.permissions.includes(Permission.CASE_GENERAL_CREATE);
        this.canCreateInvoice = currentUser.permissions.includes(Permission.INVOICE_GENERAL_CREATE) && currentOrg.type === AccountType.ATTORNEY;
        this.canCreateTask = currentUser.permissions.includes(Permission.TASKS_GENERAL_CREATE);
      }
    }));

    this.searchSuggestions$ = this.searchControl.valueChanges.pipe(
      debounceTime(300),
      tap(() => this.isLoading = true),
      switchMap(value => this.searchService.suggestions(value)
        .pipe(
          finalize(() => this.isLoading = false)
        )
      )
    );
  }

  switchAccount(account: Account): void {
    this.subs.push(
      this.userService.switchAccount(account.id).subscribe({
        next: () => {
          productFruits.safeExec(() => {
            (window as any).productFruits.services.destroy();
          });
          this.store.dispatch(GetLoggedInUser(true));
        },
        error: () => {
          console.error('Error switching account invite.');
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  openRightSidenav(): void {
    this.store.dispatch(new notificationActions.GetUnreadNotification()); // reload notifications on open
    this.rightSidenav.open().then(() => this.notificationsChecked = true);
  }

  selectSuggestion(item: SearchSuggestionModel) {
    this.addToRecentSearches(this.lastSearch);
    this.router.navigate([item.path]);
  }

  selectRecentSearch(item: string) {
    this.searchControl.setValue(item);
    this.searchBox.nativeElement.focus();
    this.searchBox.nativeElement.click();
  }

  showAddClientDialog() {
    this.store.dispatch(ShowAddClientDialog());
  }

  showNewInvoiceDialog() {
    this.store.dispatch(ShowInvoiceCreationDialog());
  }

  showAddTaskDialog() {
    this.store.dispatch(ShowTaskDialog());
  }

  expired(trialEndDate: string): string {
    return Date.now() > Date.parse(trialEndDate) ? 'expired' : 'expires';
  }

  onPageTitleButtonClicked(action: Action): void {
    this.store.dispatch(action);
  }

  private addToRecentSearches(phrase: string) {
    this.recentSearches.unshift(phrase);
    this.recentSearches.splice(5); // only store the last 5 searches
    this.recentSearches = this.recentSearches.filter(e => e); // clear out empty values
    this.$localStorage.store(RECENT_SEARCHES, this.recentSearches);
  }
}
