import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import {Organization} from '@ee/common/models';
import {AccountType, SubscriptionStatus} from '@ee/common/enums';

@Component({
  selector: 'ee-subscription-banner',
  templateUrl: './subscription-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class SubscriptionBannerComponent implements OnChanges {

  @Input()
  public org: Organization | undefined;

  enabled: boolean | undefined;
  issueType: string | undefined;
  issueDescription: string | undefined;
  actionText: string | undefined;
  actionUrl: string | undefined;

  ngOnChanges() {
    this.enabled = this.org && this.org.type === AccountType.ATTORNEY && !this.org.subscription_active;

    if (this.org?.subscription_status === 'incomplete') {
      this.issueType = 'Payment Issue';
      this.issueDescription = 'We\'re having trouble charging the card on file. Please update your payment method ' +
        'to continue your subscription.';
      this.actionText = 'Fix Issue';
      this.actionUrl = '/admin/billing';
    } else if ((this.org?.status !== SubscriptionStatus.SUBSCRIPTION_ACTIVE &&
        this.org?.status !== SubscriptionStatus.FREE_SUBSCRIPTION_ACTIVE &&
      this.org?.status !== SubscriptionStatus.SUBSCRIPTION_TRIAL_ACTIVE) ||
      (this.org?.subscription_status !== 'active' && this.org.subscription_status !== 'trialing')) {
      this.issueType = 'Subscription Issue';
      this.issueDescription = 'Looks like your subscription has lapsed. Please select a plan to continue.';
      this.actionText = 'Select Plan';
      this.actionUrl = '/plan';
    }
  }
}
